import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import tiredImage from "../images/tired.svg";

function SubBtn({text}) {
  return (
    <h3 className="bg-blue-300 text-xl font-bold inline-block my-2 p-3 mx-1">
      {text}
    </h3>
  );
}

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <section className="text-center">
        <img
          src={tiredImage}
          className="block mx-auto w-1/2 rounded-lg"
          alt="Cat and human sitting on a couch"
        />

        <h2 className="bg-blue-200 text-2xl font-bold inline-block my-8 p-3">
          Are you struggling with fatigue?
        </h2>

        <p className="leading-loose">
          If you are always tired, it could be Upper Airway Resistance Syndrome (UARS).
        </p>

        <h2 className="bg-blue-200 text-2xl font-bold inline-block my-8 p-3">
          What is UARS?
        </h2>

        <p className="leading-loose">
          Upper Airway Resistance Syndrome, or UARS, is a subtle sleep disorder that can make it tough to live your life
          to the fullest.
        </p>
      </section>
    </Layout>
  );
}

export default IndexPage;
